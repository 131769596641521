<template>
  <b-container class="pt-3">
    <b-form @submit.prevent="updateLink">
      <b-form-group
        id="linkGroup"
        label="Link"
        label-for="linkInput"
      >
        <b-form-input
          id="linkInput"
          v-model="form.link"
          required
          placeholder="Enter link url"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="linkTitleGroup"
        label="Link name"
        label-for="lastNameInput"
      >
        <b-form-input
          id="linkTitleInput"
          v-model="form.link_title"
          required
          placeholder="Enter link name"
        ></b-form-input>
      </b-form-group>

      <b-button type="submit" variant="primary" block :disabled="updating"
        >Update Link <b-spinner small v-if="updating"
      /></b-button>
    </b-form>
  </b-container>
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      updating: false,
      form: {
        link: "",
        link_title: ""
      },
    };
  },
  methods: {
    async updateLink() {
      this.updating = true;

      try {
        await this.$http.put(
          this.$store.state.apiURL + "/links/" + this.id,
          this.form,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.JWT,
            },
          }
        );

        console.log("link updated");

        this.$router.push({ name: "manageLinks" });
      } catch (error) {
        this.handleError(error);
      }

      this.updating = false;
    },
  },
  async created() {
    let response = await this.$http.get(
      this.$store.state.apiURL + "/links/" + this.id,
      {
        headers: {
          Authorization: "Bearer " + this.$store.state.JWT,
        },
      }
    );

    this.form.link = response.data.link;
    this.form.link_title = response.data.link_title;
  },
};
</script>

<style lang="scss">
@import "../../scss/theme.scss"; 
</style>
